@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700;800;900&display=swap");
@import url("https://db.onlinewebfonts.com/c/290722743dd14bbba7ce7bc395d9bf05?family=Foundry+Context+W03+Md");

@font-face {
  font-family: "Context bd";
  src: url(../src/assets/fonts/Foundry\ Context\ W03\ X\ Bd.ttf);
}
@font-face {
  font-family: "Context regular";
  src: url(../src/assets/fonts/Foundry\ Context\ W03\ Regular.ttf);
}
@font-face {
  font-family: "HankenGrotesk-Regular";
  src: url(../src/assets/fonts/HankenGrotesk-Regular/HankenGrotesk-Regular.ttf);
}
@font-face {
  font-family: "Helvetica-bold";
  src: url(../src/assets/fonts/Helvetica/Helvetica-Bold.ttf);
}
@font-face {
  font-family: "Helvetica-light";
  src: url(../src/assets/fonts/Helvetica/Helvetica.ttf);
}
.App {
  text-align: left;
}
body {
  background-color: #fbf8ee !important;
  height: auto !important;
  font-family: "Context regular" !important;
  user-select: none;
}
body::-webkit-scrollbar {
  display: none;
}

h1,
h3,
h5,
h6,
h4,
h2 {
  font-family: "Helvetica-bold";
  color: #000000 !important;
}
.ant-dropdown {
  background-color: #e2dac6 !important;
  top: 30px !important;
  border-radius: 20px !important;
  overflow: hidden;
}
.ant-dropdown-menu,
.ant-dropdown-menu-item {
  padding-bottom: 0.5em !important;
  background-color: #e2dac6 !important;
  box-shadow: none !important;
}
.ant-dropdown-menu {
  padding: 2.5em 1em 1em !important;
}
.ant-dropdown:before {
  position: fixed !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  z-index: -1049 !important;
  opacity: 1 !important;
  content: "";
  background-color: #00000090;
}
.ant-dropdown-menu-title-content a {
  font-weight: 700;
  margin-top: 0.5em;
}

.animateArrow {
  position: relative;
}
.animateArrow svg {
  position: absolute;
  top: -10px;
  transition: 500ms all ease-in-out;
  animation: App-logo-spin infinite 1s linear;
}
.ant-modal-wrap {
  background: #00000099;
  z-index: 111111 !important;
}
.popularContainer {
  /* height: 66vh; */
  /* padding-top: 2em; */
  overflow: hidden;
}

@keyframes App-logo-spin {
  from {
    left: 0;
  }
  to {
    right: 2px;
  }
}
.holdingAnimation {
  white-space: nowrap;
  font-size: 13px;
  animation: scrollText 15s linear infinite; /* adjust 10s to control the speed */
}

@keyframes scrollText {
  0% {
    transform: translateX(100%); /* start from the right of the container */
  }
  100% {
    transform: translateX(-100%); /* move text all the way to the left */
  }
}
/* @media only screen and (max-width: 992px) {
  .popularContainer {
    height: 47vh;
    padding-top: 0em;
  }
} */

#loading-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  z-index: 9999; /* Ensure it's on top of other elements */
  text-align: center;
}

#loading-bar {
  width: 0;
  height: 5px; /* Height of the loading bar */
  background-color: #0073e6; /* Loading bar color */
  position: absolute;
  top: 50%; /* Center it vertically */
  left: 0;
  transition: width 0.3s ease-in-out; /* Add a smooth transition effect */
}

/* Settings connect pinpad */

.App-header {
  background-color: #282c34;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: calc(10px + 2vmin);
  color: white;
}

.App-body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.no-close .ui-dialog-titlebar-close {
  display: none;
}

.main-div {
  margin: 0px auto;
  max-width: 800px;
  min-width: 770px;
}

.card-div {
  margin: 10px;
}

.pos-card {
  width: auto;
  min-height: 60vh;
}

.pos-output {
  border-style: outset;
}

.no-margin {
  margin: 0px;
}

.voffset1 {
  margin-top: 40px;
}

.hoffset1 {
  padding-left: 15px;
}

.center-align {
  text-align: center;
}

.box {
  display: flex;
  align-items: center;
  height: 30px;
}

.bold-font {
  font-weight: bold;
}

.no-border {
  border: none;
}

.med-size-font {
  font-size: 20px;
}

.no-padding-left {
  padding-left: 0px;
}

.warning {
  color: red;
  max-width: 350px;
}

.warning-long {
  color: red;
}

.pos-card button {
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 4px;
}
a {
  text-decoration: none !important;
}

#receiptCanvas {
  display: block;
  margin: auto;
  background-color: #fff;
}
