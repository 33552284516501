.warning-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    text-align: center;
    background-color: #fffbe6; /* Soft yellow background */
    border: 1px solid #ffcc80; /* Light orange border */
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.warning-title {
    font-size: 2rem; /* Larger title */
    color: #d32f2f; /* Dark red for warning */
    margin-bottom: 10px;
    font-weight: bold;
    text-transform: uppercase;
}

.warning-message {
    font-size: 1.25rem; /* Matches h4 size */
    line-height: 1.5;
    color: #333;
    text-align: center;
}

.highlight {
    font-weight: bold;
    color: #d32f2f; /* Highlight important words in dark red */
}